/*
* for all application
*
*/


.fullscreen-enabled {
  padding-top: 8px !important;
  overflow: scroll !important;
  background: #ffff;
}
