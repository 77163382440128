.tooltipC {
  position: relative;
  display: inline-block;
}

.tooltipC .tooltiptextC {
  visibility: hidden;
  /* width: 100px !important; */
  background-color: black;
  color: white;
  font-size: 12px;
  /* text-align: center; */
  /* border-radius: 6px; */
  padding: 5px 5px 5px 5px;
  position: absolute;
  z-index: 99999999;
  /* top: -20%; */
  left: 90px;
  /* margin-top: 5px; */
  margin-left: -60px;
  /* opacity: 0; */
  /* transition: opacity 0.3s; */
}

.tooltipC:hover .tooltiptextC {
  visibility: visible;
  opacity: 1;
}
