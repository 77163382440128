/*
* for all application
*
*/


.fullscreen-enabled {
  padding-top: 8px !important;
  overflow: scroll !important;
  background: rgb(129, 184, 80);
}
