/*
* for all application
*
*/

html, body {
  /* font-size: 20px; */
  /* margin-left: 10 !important; */
  /* padding: 20 20 20 20 !important; */
}

div {
  /* padding: 0 !important; */
}

.overRideLayer {
  z-index: 999999999;
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  /*flex-wrap: nowrap !important;*/
}

.divSummary {
  margin-bottom: 10px;
}

/* input {
font-size: 25px;
outline-color: #2684ff;
} */

.ant-select-single
.ant-select-selector {
  border-radius: 0 !important;
}

.ant-select-dropdown {
  border-radius: 0 !important;
}

.ant-select-selector {
  border-radius: 0 !important;
}

.ant-btn {
  border-radius: 0 !important;
}

.ant-modal
.ant-modal-content {
  border-radius: 0px;
}

.ant-drawer
.ant-drawer-body {
  padding: 0px;
}
.usersSelectionDiv {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.usersSelectionDivTitle {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 10px;
}
.usersSelectionDivArr {
  font-size: 20px;
}
.usersSelectionDivCheckbox {
  font-size: 20px;
  margin-bottom: 10px;
}

.ant-modal-confirm
.ant-modal-confirm-btns {
  text-align: end;
  margin-top: 0px;
}
/* .ant-select-single:not(.ant-select-customize-input)
.ant-select-selector {
  width: 100%;
  height: 50px;
  padding: 0 11px;
}

.ant-select-single:not(.ant-select-customize-input)
.ant-select-selector
.ant-select-selection-search-input {
  height: 50px;
}

.ant-select-dropdown
.ant-select-item-option-content {
  padding: 10px;
  font-size: 20px;
}

.ant-select-single.ant-select-show-arrow
.ant-select-selection-item,
.ant-select-single.ant-select-show-arrow
.ant-select-selection-placeholder {
  font-size: 20px;
  padding: 10px;
  } */
  .ant-list .ant-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    color: rgba(0, 0, 0, 0.88);
  }


  /* date picker  */
  /* .ant-picker-content th,
  .ant-picker-content td {
    font-size: 20px;
    padding: 10px;
  }

  .ant-picker-panel-container
  .ant-picker-panels {
    flex-wrap: nowrap;
    direction: ltr;
    width: 800px;
  }

  .ant-picker-panel {
    width: 400px;
  }

  .ant-picker-decade-panel,
  .ant-picker-year-panel,
  .ant-picker-quarter-panel,
  .ant-picker-month-panel,
  .ant-picker-week-panel,
  .ant-picker-date-panel,
  .ant-picker-time-panel {
    width: 400px;
  }

  .ant-picker-panel-container
  .ant-picker-panel
  .ant-picker-content,
  .ant-picker-panel-container
  .ant-picker-panel table {
    width: 380px;
  }

  .ant-picker-content th,
  .ant-picker-content td {
    font-size: 25px;
    padding: 11px;
    border: 1px solid grey;
  }

  .ant-picker-input > input {
    font-size: 25px;
  }

  .ant-picker-ranges {
    float: left;
  } */

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 4px 16px;
  }

  a:not([href]):not([class]),
  a:not([href]):not([class]):hover {
    font-size: 16px;
  }

  .tableClass tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .ant-table-column-title {
    z-index: 0;
  }

  .ant-modal-mask {
    background-color: rgb(0 58 255 / 80%);
  }

  .tableModal {
    width: auto;
  }
  /*
  .ant-btn-primary {
    width: 500px;
    height: 50px;
    font-size: 30px;
  }
  */
  /* date picker  END */

  /* TABS */
  .ant-tabs-tab {
    font-size: 18px;
    padding: 13px 50px;
  }
  /* TABS  END */

  /* Calendar */
  .events {
    line-height: 24px;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .events li {
    color: #999;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .events li span {
    vertical-align: middle;
  }

  .events li span:first-child {
    font-size: 9px;
    margin-right: 4px;
  }

  .event-warning {
    color: #fac450;
  }

  .event-normal {
    color: #108ee9;
  }

  .event-error {
    color: #f50;
  }

  .notes-month {
    text-align: center;
  }
  .notes-month section {
    font-size: 28px;
  }
  /* Calendar END */


  /* workOrder */
  .scrolling-wrapper-flexbox {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    .card {
      flex: 0 0 auto;
    }
  }

  .card {
    margin: 10px;
    border-radius: 5px;
  }

  .scrolling-wrapper,
  .scrolling-wrapper-flexbox {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  /* workOrder END */

  .blinkDivRed {
    animation: blinkerRed 2s linear infinite;
  }

  @keyframes blinkerRed {
    0% {
      background-color: #a80505;
      /* box-shadow: 0 0 5px #2ba805; */
    }
    50% {
      background-color: #d02e2e;
      /* box-shadow: 0 0 20px #49e819; */
    }
    100% {
      background-color: #a80505;
      /* box-shadow: 0 0 5px #2ba805; */
    }
  }

  .blinkDivYellow {
    animation: blinkerYellow 2s linear infinite;
  }

  @keyframes blinkerYellow {
    0% {
      background-color: #d7e203;
      /* box-shadow: 0 0 5px #2ba805; */
    }
    50% {
      background-color: #b9bf39;
      /* box-shadow: 0 0 20px #49e819; */
    }
    100% {
      background-color: #d7e203;
      /* box-shadow: 0 0 5px #2ba805; */
    }
  }

  .ant-drawer .ant-drawer-header {
    /* display: flex; */
    /* flex: 0; */
    /* align-items: center; */
    padding: 5px 24px;
    /* font-size: 40px !important; */
    /* line-height: 1.5; */
    /* border-bottom: 1px solid rgba(5, 5, 5, 0.06); */
}

.loader {
  width: 350px;
  height: 160px;
  display: block;
  margin: auto;
  background-image: radial-gradient(circle 25px at 25px 25px, #FFF 100%, transparent 0), radial-gradient(circle 50px at 50px 50px, #FFF 100%, transparent 0), radial-gradient(circle 25px at 25px 25px, #FFF 100%, transparent 0), linear-gradient(#FFF 50px, transparent 0);
  background-size: 50px 50px, 100px 76px, 50px 50px, 120px 40px;
  background-position: 0px 30px, 37px 0px, 122px 30px, 25px 40px;
  background-repeat: no-repeat;
  position: relative;
  box-sizing: border-box;
}
.loader::after {
  content: '';
  left: 0;
  right: 0;
  margin: auto;
  bottom: 20px;
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-color: #FF3D00 transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* .placeholder-contentSecond {
    height: auto;
    background-color: rgb(255, 255, 255);
    background: rgb(255, 255, 255);
    margin-left: 10px;
    padding-right: 10px;
} */

.sticky-content {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 0;
    text-align: center;
}
.sticky-content-title {
    margin: 0;
}
.placeholder-content001 {
    height: auto;
    background-color: rgb(255, 255, 255);
    background: rgb(255, 255, 255);
}
.placeholder-content {
    height: 1000px;
    background-color: rgb(255, 255, 255);
    background: rgb(255, 255, 255);
}
.placeholder-title {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding-left: 20px;
}
.placeholder-separator {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.tooltipCard {
  position: relative;
  display: flex;
  /* display: inline-block; */
  /* border-bottom: 1px dotted black; */
}

.tooltipCard .tooltiptextCard {
  visibility: hidden;
  /* width: 120px; */
  background-color: #1F1F1F;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px 5px 5px;
  /* inline-size: 300px; */
  height: 300;
  /* max-width: 300px; */
  /* overflow-wrap: break-word; */
  /* Position the tooltip */
  position: fixed;
  z-index: 9;
}

.tooltiptextCardAb {
  /* position: absolute; */
}

.tooltipCard:hover .tooltiptextCard {
  visibility: visible;
}

.wrapper{
  position: fixed;
  /* top: 100px; */
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* margin-top: 80px; */
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.one{
  float: left;
  /* background: red; */
  width: 20%;
  height:100px;
}

.two{
  float: right;
  /* background: blue; */
  width: 40%;
  height:100px;
}

.three{
  float: right;
  /* background: green; */
  width: 40%;
  height:100px;
}

@media only screen and (max-width: 600px){
  .one{
    -webkit-order: 1;
    -ms-order: 1;
    order: 1;
    width: 100%;
  }
  .two{
    -webkit-order: 2;
    -ms-order: 2;
    order: 2;
    width: 100%;
  }
  .three{
    -webkit-order: 3;
    -ms-order: 3;
    order: 3;
    width: 100%;
  }
}

.lableGauge {
  /* --base-font-size: 16px; */
  z-index: 9;
  position: absolute;
  margin: auto;
  top: 92%;
  left: 50%;
  transform: translate(-50%,-50%);
  cursor: pointer;
}

@media screen {
  .titleGauge {
    font-size: calc(16px + 4px);

  }
}


#titleGauge {
  z-index: 9;
  position: absolute;
  margin: auto;
  top: 40%;
  left: 50%;
  font-size: 5;
  transform: translate(-50%,-50%);
  font-weight: bold;
}
@media screen and (width: 80px) {
  #titleGauge {
    z-index: 9;
    position: absolute;
    margin: auto;
    top: 40%;
    left: 50%;
    font-size: 10;
    transform: translate(-50%,-50%);
    fontWeight: bold;
  }
}

/* Styles for a narrower div */
@media screen and (width: 100px) {
  #titleGauge {
    z-index: 9;
    position: absolute;
    margin: auto;
    top: 40%;
    left: 50%;
    font-size: 20;
    transform: translate(-50%,-50%);
    fontWeight: bold;
  }
}

@keyframes blink {
  0% {
    opacity: .2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: .2;
  }
}

.loading span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.loading span:nth-child(2) {
  animation-delay: .2s;
}

.loading span:nth-child(3) {
  animation-delay: .4s;
}

.blink-text{
  color: #ffffff;
  font-weight: bold;
  /* font-size: 2rem; */
  animation: blinkingText 2s infinite;
}
@keyframes blinkingText{
  0%		{ background: #1677FF; }
  /* 0%		{ color: #ffffff; } */
  /* 25%		{ color: #1056c0;} */
  /* 50%		{ color: #ef0a1a;} */
  /* 75%		{ color: #254878;} */
  100%	{ background: #FF4D4F; }
}

.blinkIcon {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.ant-modal-confirm
.ant-modal-confirm-paragraph {
  max-width: calc(100%);
}

.progress-container {
  position: relative;
  width: 100%;
  text-align: center;
}

.progress-bar {
  position: relative;
  width: 100%;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 0px;
  margin: 0px 0;
  overflow: hidden;
  color: #fff;
  font-weight: bold;
}

.progress-bar-inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #4caf50;
  z-index: 1;
  transition: width 0.5s ease-in-out;
}

.progress-bar-inner-2 {
  /* background-color: #f0f0f0; */
  background-color: hsla(0, 0%, 0%, 0);
  z-index: 1;
  border-right: 2px solid black;
}

.progress-bar-inner-3 {
  background-color: hsla(0, 100%, 50%, 0.42);
}

.progress-bar-inner-4 {
  background-color: #ff0000;
}

.progress-label {
  font-size: 16px;
  margin-top: 5px;
}

.updatePalletsDiv {
  padding: 0px 0px 10px 10px;
}
.updatePalletsDivTitle {
  font-size: 20px;
  padding: 0px 0px 10px 10px;
}
.container {
  /* font-size: 2px; */
  padding: 10px 10px 10px 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  /* grid-template-rows: 1fr 1fr 1fr 1fr 1fr; */
  gap: 10px 10px;
  grid-auto-flow: row;
  grid-template-areas:
    "dn kupec idIzdelka naroceno buttonNext"
    "empty0 prejemnik izdelek izdelek izdelek"
    "paletizacija orodja kliseji recepture identi"
    "prevOp currentSpeed currentSpeed currentSpeed nextOp"

    "graf01 graf01 graf01 graf01 graf01"
    "graf02_a graf02_b graf02_c graf02_d graf02_e"
    "graf03_a graf03_b graf03_c graf03_d graf03_e"

    "button_2_a_71 button_2_b_71 button_2_c_71 button_2_d_71 button_2_e_71"
    "button_2_a button_2_b button_2_c button_2_d button_2_e"
    "button_2_f button_2_g button_2_h button_2_i button_2_j"
    "zastoji zastoji zastoji zastoji zastoji"
    "paletGraf paletGraf paletGraf paletGraf paletGraf"
    "autoStatus autoStatus autoStatus autoStatus autoStatus";
}

.containerPlan {  display: grid;
  padding: 10px 10px 10px 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  /* grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; */
  gap: 10px 10px;
  grid-auto-flow: row;
  grid-template-areas:
  "startOfShift operatorsOnAsset operatorsOnAsset operatorsOnAsset buttonNext"
  "EventsChart EventsChart EventsChart EventsChart EventsChart"
  "graph01 graph01 graph01 graph01 graph01"
  "casNastavitve casIzdelave casPavze cazZastojev ."
  "avgACT avgNorma . . ."
  "c_a c_b c_c c_d c_e"
  "FinishedOperations FinishedOperations FinishedOperations FinishedOperations FinishedOperations"
  /* "button_a button_b button_c button_d button_e" */
  "OperationsOnAsset OperationsOnAsset OperationsOnAsset OperationsOnAsset OperationsOnAsset";
}

.operatorsOnAsset { grid-area: operatorsOnAsset; }
.buttonNext { grid-area: buttonNext; }
.startOfShift { grid-area: startOfShift; }
.EventsChart { grid-area: EventsChart; }
.graph01 { grid-area: graph01; }
.casNastavitve { grid-area: casNastavitve; }
.casIzdelave { grid-area: casIzdelave; }
.casPavze { grid-area: casPavze; }
.cazZastojev { grid-area: cazZastojev; }
.avgACT { grid-area: avgACT; }
.avgNorma { grid-area: avgNorma; }
.c_a { grid-area: c_a; }
.c_b { grid-area: c_b; }
.c_c { grid-area: c_c; }
.c_d { grid-area: c_d; }
.c_e { grid-area: c_e; }
.FinishedOperations { grid-area: FinishedOperations; }
.button_a { grid-area: button_a; }
.button_b { grid-area: button_b; }
.button_c { grid-area: button_c; }
.button_d { grid-area: button_d; }
.button_e { grid-area: button_e; }
.OperationsOnAsset { grid-area: OperationsOnAsset; }

.containerNotes {
  padding: 10px 10px 10px 10px;
  display: grid;
  grid-template-columns: 1fr;
  /* grid-template-rows: 1fr 1fr 1fr; */
  gap: 10px 10px;
  grid-auto-flow: row;
  grid-template-areas:
  "qualityControl"
  "pantheonNotes"
  "notes";
}

.containerPaletizacija {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-template-rows: 1fr 1fr; */
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
  "paletizacija_a paletizacija_b"
  "paletizacija_c paletizacija_d";
}

.paletizacija_a { grid-area: paletizacija_a; }
.paletizacija_b { grid-area: paletizacija_b; }
.paletizacija_c { grid-area: paletizacija_c; }
.paletizacija_d { grid-area: paletizacija_d; }


/* 1/1 */
/* 1 */
.qualityControl { grid-area: qualityControl; }
.pantheonNotes { grid-area: pantheonNotes; }
.notes { grid-area: notes; }


/* 2/1 */
/* 1 */
.dn { grid-area: dn; }
.kupec { grid-area: kupec; }
.idIzdelka { grid-area: idIzdelka; }
.naroceno { grid-area: naroceno; }
.buttonNext { grid-area: buttonNext; }

/* 2 */
.empty0 { grid-area: empty0; }
.prejemnik { grid-area: prejemnik; }
.izdelek { grid-area: izdelek; }

/* 3 */
.paletizacija {grid-area: paletizacija; }
.orodja { grid-area: orodja; }
.kliseji { grid-area: kliseji; }
.recepture { grid-area: recepture; cursor: pointer; }
.identi { grid-area: identi; }

/* 4 */
.prevOp { grid-area: prevOp; }
.currentSpeed { grid-area: currentSpeed; }
.nextOp { grid-area: nextOp; }

/* 5 */
.graf01 { grid-area: graf01; }

/* 6 */
.graf02_a { grid-area: graf02_a; }
.graf02_b { grid-area: graf02_b; }
.graf02_c { grid-area: graf02_c; }
.graf02_d { grid-area: graf02_d; }
.graf02_e { grid-area: graf02_e; }

/* 7 */
.graf03_a { grid-area: graf03_a; }
.graf03_b { grid-area: graf03_b; }
.graf03_c { grid-area: graf03_c; }
.graf03_d { grid-area: graf03_d; }
.graf03_e { grid-area: graf03_e; }

/* 7.1 */
.button_2_a_71 { grid-area: button_2_a_71; }
.button_2_b_71 { grid-area: button_2_b_71; }
.button_2_c_71 { grid-area: button_2_c_71; }
.button_2_d_71 { grid-area: button_2_d_71; }
.button_2_e_71 { grid-area: button_2_e_71; }

/* 8 */
.button_2_a { grid-area: button_2_a; }
.button_2_b { grid-area: button_2_b; }
.button_2_c { grid-area: button_2_c; }
.button_2_d { grid-area: button_2_d; }
.button_2_e { grid-area: button_2_e; }

/* 9 */
.button_2_f { grid-area: button_2_f; }
.button_2_g { grid-area: button_2_g; }
.button_2_h { grid-area: button_2_h; }
.button_2_i { grid-area: button_2_i; }
.button_2_j { grid-area: button_2_j; }






/* 109 */
.zastoji { grid-area: zastoji; }
/* .zastoji { grid-area: zastoji; margin-left: -25px; } */

/* 11 */
.paletGraf { grid-area: paletGraf; }

/* 12 */
.autoStatus { grid-area: autoStatus; }



.progress-containerVertical {
  cursor: pointer;
  display: inline-flex;
  position: relative;
  width: 100px; /* Adjust width as needed */
  height: 50px; /* Adjust height as needed */
  /* height: 300px; */
  /* margin: 50px auto; */
  text-align: center;
}
.progress-barVertical {
  margin: 10px;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  border-radius: 0px;
  overflow: hidden;
  color: #fff;
}
.progress-barVertical-inner {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: height 0.5s ease-in-out;
}

.progress-barVertical-inner-1 {
  background-color: #4caf50;
}

.progress-barVertical-inner-2 {
  background-color: hsla(0, 0%, 100%, 0);
}


.progress-container001 {
  width: 100%;
  text-align: center;
}

.progress-bar001 {
  width: 100%;
  height: 30px;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin: 10px 0;
  overflow: hidden;
  position: relative;
}

.progress-bar001-inner {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.progress-bar001-inner-2 {
  background-color: #2196f3;
}

.progress-bar001-inner-3 {
  background-color: #ff9800;
}

.progress-bar001-inner-4 {
  background-color: #e91e63;
}

.progress-label001 {
  font-size: 16px;
  margin-top: 5px;
}

.tableCustom {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.tdCustom, .thCustom {
  /* border: 1px solid #dddddd; */

  text-align: left;
  padding: 5px;
  width: 50px;
}

.aktualno {
  /* border-right: 1px solid #5b5b5c; */
}

.div001 {
  text-align: right;
}

.headerA {
  float: right;
}

.trCustom:nth-child(even) {
  background-color: #f8f8f8;
}

.inputCustom {
  padding: 0px 0px 0px 0px;
  border-radius: 0;
}

.containerFilter {  display: grid;
  padding: 5px 5px 5px 5px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 5px;
  grid-auto-flow: row;
  grid-template-areas:
    "input datePicker select";
}

.input { grid-area: input; }
.datePicker { grid-area: datePicker; }
.select { grid-area: select; }

.containerEvents {  display: grid;
  /* font-size: 16px; */
  color: #fff;
  height: 32px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "setupTime productionTime breakTime downtime";
}

.setupTime { grid-area: setupTime; background: #50c4fa; display: flex; justify-content: center; align-items: center; }
.productionTime { grid-area: productionTime; background: #0c4159; display: flex; justify-content: center; align-items: center; }
.breakTime { grid-area: breakTime; background: #757575; display: flex; justify-content: center; align-items: center; }
.downtime { grid-area: downtime; background: #FF7F7F; display: flex; justify-content: center; align-items: center; }

.containerMiniColor {  display: grid;
  color: #fff;
  text-align: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "preparationDuration inProductionDuration"
    "pauseDuration interruptDuration";
}
.preparationDuration { grid-area: preparationDuration; background: #50c4fa; }
.inProductionDuration { grid-area: inProductionDuration; background: #0c4159; }
.interruptDuration { grid-area: interruptDuration; background: #FF7F7F; }
.pauseDuration { grid-area: pauseDuration; background: #757575; }

.containerOpSummary {  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-template-rows: 1fr 1fr 1fr; */
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "leftOpSummary rightOpSummary"
    "leftOpSummaryDetails rightOpSummaryDetails"
    "palletization palletization"
    "summaryFooter summaryFooter";
}

.leftOpSummary { grid-area: leftOpSummary; border-bottom: 1px solid #f5f5f5; padding: 0 0 10px 10px; }
.rightOpSummary { grid-area: rightOpSummary; border-bottom: 1px solid #f5f5f5; padding: 0 0 10px 10px; }
.leftOpSummaryDetails {  display: grid;
  grid-template-columns: 1fr;
  /* grid-template-rows: 1fr 1fr; */
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "."
    "leftOpSummarySummary";
  grid-area: leftOpSummaryDetails;
  border-right: 1px solid #f5f5f5;

  padding: 10px 0 0 10px;
}
.leftOpSummarySummary { grid-area: leftOpSummarySummary; border-right: 1px solid #f5f5f5; border-top: 1px solid #f5f5f5; padding: 10px 0 0 0; }
.rightOpSummaryDetails { grid-area: rightOpSummaryDetails; padding: 10px 0 0 10px; }
.palletization { grid-area: palletization; border-top: 1px solid #f5f5f5; padding: 0 0 0 10px; padding-top: 10px; }
.summaryFooter { grid-area: summaryFooter; border-top: 1px solid #f5f5f5; padding: 10px 0 0 0; }

.containerRightOpSummaryDetail {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* grid-template-rows: 1fr 1fr 1fr 1fr 1fr; */
  gap: 0px 0px;
  grid-template-areas:
    "proizvodniCasi current prevShift sum"
    "startSetup currentDurationSetup prevDurationSetup sumDurationSetup"
    "startProduction currentDurationProduction prevDurationProduction sumDurationProduction"
    "endProduction . . ."
    "jams currentDurationJams prevDurationJams sumDurationJams"
    "breaks currentDurationBreaks prevDurationBreaks sumDurationBreaks"
    ". . . .";
}

.proizvodniCasi { grid-area: proizvodniCasi; }
.current { grid-area: current; }
.prevShift { grid-area: prevShift; }
.sum { grid-area: sum; }

.currentDurationSetup { grid-area: currentDurationSetup; padding: 10px 10px 5px 5px; }
.startSetup { grid-area: startSetup; padding: 10px 10px 5px 5px; }
.currentDurationProduction { grid-area: currentDurationProduction; padding: 10px 10px 5px 5px; }
.prevDurationSetup { grid-area: prevDurationSetup; padding: 10px 10px 5px 5px; }
.sumDurationSetup { grid-area: sumDurationSetup; padding: 10px 10px 5px 5px; }
.startProduction { grid-area: startProduction; padding: 10px 10px 5px 5px; }
.prevDurationProduction { grid-area: prevDurationProduction; padding: 10px 10px 5px 5px; }
.sumDurationProduction { grid-area: sumDurationProduction; padding: 10px 10px 5px 5px; }
.endProduction { grid-area: endProduction; padding: 10px 10px 5px 5px; }
.jams { grid-area: jams; padding: 0px 0px 0px 5px; }
.currentDurationJams { grid-area: currentDurationJams; padding: 0px 0px 0px 5px; }
.prevDurationJams { grid-area: prevDurationJams; padding: 0px 0px 0px 5px; }
.sumDurationJams { grid-area: sumDurationJams; padding: 0px 0px 0px 5px; }
.breaks { grid-area: breaks; padding: 0px 0px 0px 5px; }
.currentDurationBreaks { grid-area: currentDurationBreaks; padding: 0px 0px 0px 5px; }
.prevDurationBreaks { grid-area: prevDurationBreaks; padding: 0px 0px 0px 5px; }
.sumDurationBreaks { grid-area: sumDurationBreaks; padding: 0px 0px 0px 5px; }


.containerInsertPalletization {  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-template-rows: 1fr 1fr 1fr 1fr 1fr; */
  gap: 10px 10px;
  /* grid-auto-flow: row; */
  grid-template-areas:
    "updateProperty palletization_1 palletization_1"
    "updateProperty palletization_1 palletization_1"
    "odstopanjeKolicine transportniKarton zlozenka"
    "odstopanjeKolicine kasirana ostalo"
    ". . .";
}
.updateProperty { grid-area: updateProperty; }
.odstopanjeKolicine { grid-area: odstopanjeKolicine; }
.transportniKarton { grid-area: transportniKarton; }
.zlozenka { grid-area: zlozenka; }
.kasirana { grid-area: kasirana; }
.ostalo { grid-area: ostalo; }
.palletization_1 { grid-area: palletization_1; }

.containerPrint001 {
  break-after: page;
  padding: 10px 10px 10px 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr; */
  gap: 10px 0px;
  /* grid-auto-flow: row; */
  grid-template-areas:
    "title logo"
    "info info_2"
    "narocnik narocnik"
    "body body"
    "footer footer"
    ". .";
}
.title { grid-area: title; padding: 0 0 10px 0; }
.logo { grid-area: logo; text-align: end; padding: 0 0 10px 0; }
.info { grid-area: info; border-bottom: 2px solid #000000; padding: 0 0 10px 0; }
.info_2 { grid-area: info_2; text-align: end; border-bottom: 2px solid #000000; padding: 0 0 10px 0; }
.narocnik { grid-area: narocnik; border-bottom: 2px solid #9a9696; padding: 0 0 10px 0; }
.body { grid-area: body; }
.footer {
  grid-area: footer;
  /* border-top: 2px dotted #9a9696; */
  /* position: fixed; */
  /* left: 0; */
  /* bottom: 0; */
  /* width: 100%; */
  /* background-color: red; */
  /* color: white; */
  /* text-align: center; */
}

.footerDivPrint {
  border-top: 2px dotted #9a9696;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: red;
  color: white;
  text-align: center;
}

.InsertPalletizationDiv {
  margin: 5px 5px 5px 10px;
}

.InsertPalletizationTitle {
  font-size: 18px;
  font-weight: bold;
}

.InsertPalletizationSpan {
  margin-right: 30px;
}

.interruptGroup {
  padding: 10px 10px 10px 10px;
  margin: 0px 0px 0px 0px;
  min-height: 110px;
  font-size: 18px;
  cursor: pointer;
  color: #ffffff;
}

.interruptEvents {
  padding: 10px 10px 10px 10px;
  margin: 0px 0px 0px 0px;
  min-height: 110px;
  font-size: 18px;
  cursor: pointer;
  color: #ffffff;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.spinnerCustom {
  z-index: 9999999999;
}

.containerSliderTimeline {
  display: grid;
  grid-template-columns: 2fr 0.1fr;
  /* grid-template-rows: 1fr; */
  gap: 0px 10px;
  grid-auto-flow: row;
  grid-template-areas:
    "sliderTimeline buttons";
}

.sliderTimeline { grid-area: sliderTimeline; }
.buttons {
  grid-area: buttons;
  position: relative;
  display: inline-flex;
  top: 21px;
  left: 20px;
  height: 35px;
}

.spanSliderTimeline {
  position: relative;
  display: inline-flex;
  top: 21px;
  left: 20px;
  height: 35px;
}
.popconfirmSliderTimeline {
  margin-right: 10px;
}

.markLabelA {
  margin: -10px;
  white-space: nowrap;
  /* width: 50px; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.markLabelB {
  margin: -25px;
  white-space: nowrap;
  /* width: 50px; */
  overflow: hidden;
  text-overflow: ellipsis;
}
